import styles from './gallery.module.scss';
import GalleryItem from '../../components/GalleryItem/GallerItem';
import { galleryMint } from '../../images';
import { useState } from 'react';

const Gallery = () => {
    const testArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let [mintCount, setMintCount] = useState(1);

    const addCount = () => {
        setMintCount(mintCount + 1);
    }

    const removeCount = () => {
        if(mintCount > 1) {
            setMintCount(mintCount - 1);
        }
    }

    return (
        <section className={styles.gallery}>
            <div className={`${styles.gallery__inner} container`}>
                <h2 className={styles.gallery__items__title__mobile}>Gallery</h2>
                <div className={styles.gallery__items__wrap}>
                    <h1 className={styles.gallery__items__title}>Gallery</h1>
                    <div className={styles.gallery__items}>
                        {
                            testArr.map((item) => (
                                <GalleryItem key={item} />
                            ))
                        }
                    </div>
                </div>

                <div className={styles.mint}>
                    <div className={styles.mint__inner}>
                        <div className={styles.mint__img__wrap}>
                            <img src={galleryMint} className={styles.mint__img} alt="Mint Now" />
                        </div>
                        <div className={styles.mint__details}>
                            <h2 className={styles.mint__details__now}>mint now</h2>
                            <h4 className={styles.mint__details__info}>Price</h4>
                            <h3 className={styles.mint__details__subtitle}>0.15 ETH Per NFT</h3>
                            <h4 className={styles.mint__details__info}>Limits</h4>
                            <h3 className={styles.mint__details__subtitle}>10 Per Transaction</h3>
                            <div className={styles.count}>
                                <span
                                    className={styles.count__remove}
                                    onClick={removeCount}
                                >-</span>
                                <input
                                    type="text"
                                    className={styles.count__value}
                                    value={mintCount}
                                    disabled
                                />
                                <span
                                    className={styles.count__add}
                                    onClick={addCount}
                                >+</span>
                            </div>
                            <button className="btn__connect__wallet">connect wallet</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Gallery;