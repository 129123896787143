import {
  roadmapSub,
  roadmapSub1,
  roadmapSub2,
  roadmapSub3,
  team1,
  team2,
  team3,
  team4,
} from "./images";

export const PATHS = {
  home: "/",
  roadmap: "/roadmap",
  roadmapSub: "/roadmap-sub",
  gallery: "/gallery",
  ourBarber: "/our-barber",
  aboutUs: "/about-us",
};

export const MENU = [
  { id: 1, name: "Roadmap", url: PATHS.roadmap },
  { id: 2, name: "Gallery", url: PATHS.gallery },
  { id: 3, name: "Our barber", url: PATHS.ourBarber },
  { id: 4, name: "About us", url: PATHS.aboutUs },
];

export const team = [
  {
    id: 1,
    name: "Cagtus",
    position: "Co-Founder",
    info: "Crypto Enthusiast and loves a Bowl cut",
    twitter: "@CagtusCag",
    img: team1,
  },
  {
    id: 2,
    name: "Douglas Doggus",
    position: "Co-Founder",
    info: "Hates 9-5 and haircuts",
    twitter: "@DouglasDoggus",
    img: team2,
  },
  {
    id: 3,
    name: "RV",
    position: "Artist",
    info: "Modern day Picasso that rocks a mop",
    img: team3,
  },
  {
    id: 4,
    name: "Mommy",
    position: "Developer",
    info: "A genius that has worked on 100+ projects as a blockchain and game developer while simultaneously flexing his iconic Bieber sweep hairstyle. We can truely say this man has done it all",
    img: team4,
  },
];

export const roadmaps = [
  {
    id: "01",
    title: "our vision",
    img: roadmapSub,
    slug: "vision",
    text: `
    <p>Stay tuned for a ride of a lifetime! With the goal of being the first operating Barber Shop within the verse, we continue to plan and implement new and exciting opportunities within our roadmap, with a pure focus on becoming a community central project. This is where our DAO implementation comes into play! Holders = Owners. As owners, the community will vote for decisions that will benefit and grow the future of The Barber Shop.</p>
    <p>The Barber Shop evokes a sense of family, spirit and community with an important emphasis on our values being Care, Commitment and Loyalty. The Barber Shop is truly the place for fresh cuts and hangouts. Come on in and have the experience of a lifetime!</p>
    `,
  },
  {
    id: "02",
    title: "community",
    img: roadmapSub1,
    slug: "community",
    exploring: true,
    text: `
    <p>
        <ul>
            <li>The Barbershop Radio (T.B.S Radio) will be released so you can listen to your favorite old-school hip-hop tunes before, during and after your cut.</li>
            <li>We will release the TBS fashion/Barber essentials line, for our community to rock and feel fresh.</li>
            <li>Community pool events will be conducted.</li>
            <li>Exploring IRL events is a huge opportunity for us, hosting live events, parties and hang outs for our people.</li>
        </ul>
    </p>
    `,
  },
  {
    id: "03",
    title: "objectives",
    img: roadmapSub2,
    slug: "objectives",
    exploring: true,
    text: `
    <p>
        <ul>
            <li>Looking to fulfill partnerships with companies/brands who have the same perception, drive, focus and values as us.</li>
            <li>T.B.S is a brand that strives to be original, unique and creative. Trends come and go, however being unconventional and paving your own path sets you apart from others.</li>
            <li>Become one of the largest and strongest communities operating in the Metaverse, allowing members to provide feedback on the project as well as vote on what they would like implemented.</li>
        </ul>
    </p>
    `,
  },
  {
    id: "04",
    title: "The basement",
    img: roadmapSub3,
    slug: "basement",
    exploring: true,
    text: `
        <p>The backbone of the shop… Come down, hang with us, have the time of your life… A place filled with wonders, beautiful cars, flat screens, and most importantly, priceless goods. SHHHH… DON’T TELL ANYONE……</p>
        <p>Fractional ART= INCREASED UTILITY. As we work on implementing the DAO, sales from The Barber Shop will be reinvested into the project, where purchases of valuable NFTs will be bought and stored in our basement. The Art stored will be fractionalized and most importantly act as the foundation for our community.</p>
        <p>Now, who wants a LOLLIPOP? We are sick and tired of bad haircuts, and sobbing nights. Here, have a $LOLLIPOP, it’ll get you in a better mood, trust us. Not only that, as the universe grows our utility does too, granting access to new drops and exclusive giveaways.</p>
    `,
  },
];
