import { mainBarber, ourBarberBg } from '../../images';
import styles from './ourbarber.module.scss';

const OurBarber = () => {
    return (
        <section className={styles.our__barber}>
            <div className={`${styles.our__barber__inner} container`}>
                <div className={styles.our__barber_left}>
                    <h1 className={styles.our__barber__title}>Our barber</h1>
                    <p className={styles.our__barber__description}>
                        Meet our Barber Pepe! Despite being a 30-year veteran in his field Pepe comes from
                        humble beginnings. Born in the outskirts of Mexico, deriving from a family of Bullfighters
                        Pepe knew he wouldn’t continue down the same path and discovered his passion for cutting
                        hair at a young age. From the tender age of 11 Pepe started cutting hair on the street,
                        using his imagination to create quirky and striking looks to bring out the best features of
                        his clients. From there he was picked up by the cartel due to his impressive skill and talent,
                        being their right-hand man for looking fresh until he decided to leave it all behind and open
                        up his own place in the metaverse.
                    </p>
                </div>

                <div className={styles.join__community}>
                    <div className={styles.community__inner}>
                    </div>
                    <div className={styles.community__img__wrap}>
                        <img className={styles.community__img} src={mainBarber} alt="Barber Image" />
                    </div>
                </div>
            </div>
            <div className={styles.barber_bg}>
                <img src={ourBarberBg} alt="Barber Image" />
            </div>
        </section>
    );
};

export default OurBarber;