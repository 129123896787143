import { mainBarber, mainBrand, mintImg } from '../../images';
import styles from './home.module.scss';

const Home = () => {

    return (
        <section className={`${styles.home}`}>
            <div className={`${styles.home__inner} container-lg`}>
                <div className={styles.mint}>
                    <div className={styles.mint__img__wrap}>
                        <img src={mintImg} className={styles.mint__img} alt="Mint" />
                    </div>
                    <div className={styles['mint__now']}>
                        <h1 className={styles.mint__title}>mint now</h1>
                        <button className='btn__connect__wallet'>connect wallet</button>
                    </div>
                </div>

                <div className={styles.brand}>
                    <img src={mainBrand} className={styles.brand__img} alt="Main Brand" />
                </div>
                {/* <div className={styles.join__community}>
                    <div className={styles.community__inner}>
                        <p className={styles.community__text}>join the community</p>
                    </div>
                    <div className={styles.community__img__wrap}>
                        <img className={styles.community__img} src={mainBarber} alt="Barber Image" />
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default Home;