import TeamItem from "../../components/TeamItem/TeamItem";
import { team } from "../../const";
import styles from "./aboutus.module.scss";

const MeetTheTeam = () => {
  return (
    <section className={styles.meet__the__team}>
      <div className={`${styles.meet__the__team__inner} container`}>
        <h1 className={styles.meet__the__team__title}>meet the Team</h1>
        <div className={styles.team__items__wrap}>
          {team.map((employes) => (
            <TeamItem key={employes.id} {...employes} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default MeetTheTeam;
