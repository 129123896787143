import cn from "classnames";
import { roadmaps } from "../../const";
import styles from "./roadmapsub.module.scss";

const RightMenu = ({ visibleItem, handleSectionSelection }) => {
  return (
    <div className={styles.article}>
      {roadmaps.map((roadmap) => (
        <article
          key={roadmap.id}
          onClick={handleSectionSelection}
          id={roadmap.slug}
          className={cn(styles.article__sub, {
            ["d-none"]: visibleItem === roadmap.slug,
          })}
        >
          <img
            src={roadmap.img}
            className={styles.article__sub__img}
            alt={roadmap.title}
          />
          <div className={styles.article__sub__wrap}>
            <h4 className={styles.article__sub__num}>{roadmap.id}</h4>
            <h3 className={styles.article__sub__title}>{roadmap.title}</h3>
          </div>
        </article>
      ))}
    </div>
  );
};

export default RightMenu;
