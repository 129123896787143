import styles from "./roadmap.module.scss";
import {
    roadmap1,
    roadmap2,
    roadmap3,
    roadmap4
  } from "../../images";
  
const IntroMenu = ({ handleSectionSelection }) => (
  <div className={styles.roadmap__items__wrap}>
    <div className={styles.roadmap__item__left}>
      <div
        id="vision"
        onClick={handleSectionSelection}
        className={`${styles.item__inner} ${styles.item_vision}`}
      >
        <img
          src={roadmap1}
          className={styles.item__inner__img}
          alt="our vision"
        />
        <div className={styles.item__info__wrap}>
          <h4 className={styles.item__num}>01</h4>
          <h3 className={styles.item__title}>our vision</h3>
        </div>
      </div>
    </div>
    <div className={styles.roadmap__item__right}>
      <div
        id="community"
        onClick={handleSectionSelection}
        className={`${styles.item__inner} ${styles.item_community}`}
      >
        <img
          src={roadmap2}
          className={styles.item__inner__img}
          alt="COMMUNITY"
        />
        <div className={styles.item__info__wrap}>
          <h4 className={styles.item__num}>02</h4>
          <h3 className={styles.item__title}>COMMUNITY</h3>
        </div>
      </div>
      <div
        id="basement"
        onClick={handleSectionSelection}
        className={`${styles.item__inner} ${styles.item_basement}`}
      >
        <img
          src={roadmap4}
          className={styles.item__inner__img}
          alt="THE BASEMENT"
        />
        <div className={styles.item__info__wrap}>
          <h4 className={styles.item__num}>04</h4>
          <h3 className={styles.item__title}>THE BASEMENT</h3>
        </div>
      </div>
      <div
        id="objectives"
        onClick={handleSectionSelection}
        className={`${styles.item__inner} ${styles.item_objectives}`}
      >
        <img
          src={roadmap3}
          className={styles.item__inner__img}
          alt="OBJECTIVES"
        />
        <div className={styles.item__info__wrap}>
          <h4 className={styles.item__num}>03</h4>
          <div className={styles.item__objectives__child}>
            <h3 className={styles.item__title}>OBJECTIVES</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default IntroMenu;
