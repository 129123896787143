import { galleryItem } from '../../images';
import styles from './Gallery.module.scss';

const GallerItem = () => {
    return (
        <article className={styles.gallery__item}>
            <div className={styles.gallery__item__img__wrap}>
                <img src={galleryItem}  className={styles.gallery__item__img} alt="Gallery Image" />
            </div>
            <div className={styles.item__info}>
                <span className={styles.item__info__name}>T.B.S</span>
                <span className={styles.item__info__number}>No.0000</span>
            </div>
        </article>
    );
};

export default GallerItem;