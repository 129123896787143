import styles from "./roadmapsub.module.scss";

const Detail = ({ text, title, id, exploring }) => {
  return (
    <div className={styles.roadmapsub__details}>
      <h4 className={styles.roadmapsub__num}>{id}</h4>
      <h3 className={styles.roadmapsub__subtitle}>
        {title}
        {exploring && (
          <span className={styles.roadmapsub__exploring}>EXPLORING</span>
        )}
      </h3>
      <div
        className={styles.roadmapsub__desc}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default Detail;
