import cn from "classnames";
import { teamImg, TwitterIcon } from "../../images";
import styles from "./TeamItem.module.scss";

const AboutUs = ({ name, position, twitter, info, img }) => {
  return (
    <div className={cn(styles["team__item"])}>
      <div className={styles.item__img__wrap}>
        <img src={img} className={styles.item__img} alt="Item Image" />
      </div>
      <div className={styles.item__info}>
        <h3 className={styles.item__title}>{name}</h3>
        <h4 className={styles.item__position}>{position}</h4>
        <p className={styles.item__desc}>{info}</p>
        {twitter && (
          <a target="_blank" href={`https://twitter.com/${twitter}`}>
            <div className={styles.item__social}>
              <i className={styles.social__icon}>
                <TwitterIcon className={styles.social__icon__img} />
              </i>
              <span className={styles.social__tag}>{twitter}</span>
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
