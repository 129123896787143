import { useState } from "react";
import { roadmaps } from "../../const";
import RightMenu from "./RightMenu";
import Detail from "./Detail";
import IntroMenu from "./IntroMenu";
import styles from "./roadmap.module.scss";
import { SwitchTransition, CSSTransition } from "react-transition-group";

const RoadMap = () => {
  const [showRoadmap, setShowRoadmap] = useState(false);
  const [visibleItem, setVisibleItem] = useState("hidden");

  const handleSectionSelection = (e) => {
    const target = e.currentTarget;
    const { id } = target;
    setVisibleItem(id);
    setShowRoadmap(true);
  };

  return (
    <section className={styles.roadmap}>
      <div className={`${styles.roadmap__inner} container`}>
        <h1 className={styles.roadmap__inner__title}>roadmap</h1>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={showRoadmap}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
            <div className={styles.roadmap__items__wrap}>
              {!showRoadmap ? (
                <IntroMenu handleSectionSelection={handleSectionSelection} />
              ) : (
                <>
                  {roadmaps.map(
                    (roadmap) =>
                      roadmap.slug === visibleItem && (
                        <Detail key={roadmap.id} {...roadmap} />
                      )
                  )}
                  <RightMenu
                    visibleItem={visibleItem}
                    handleSectionSelection={handleSectionSelection}
                  />
                </>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </section>
  );
};

export default RoadMap;
