import { Route, Routes, useLocation } from 'react-router';
import './styles/index.scss';
import Home from "./pages/Home";
import RoadMap from './pages/Roadmap';
import { PATHS } from './const';
import Layout from './layout';
import OurBarber from './pages/OurBarber/Index';
import AboutUs from './pages/AboutUs';
import Gallery from './pages/Gallery';
import { TransitionGroup } from 'react-transition-group';
import { CSSTransition } from 'react-transition-group';


function App() {
  const location = useLocation()
  return (
    <TransitionGroup>
      <CSSTransition
        classNames='fade'
        timeout={400}
        key={location.key}
      >
        <Routes>
          <Route path={PATHS.home} element={<Layout><Home /></Layout>} />
          <Route path={PATHS.roadmap} element={<Layout><RoadMap /></Layout>} />
          <Route path={PATHS.ourBarber} element={<Layout><OurBarber /></Layout>} />
          <Route path={PATHS.aboutUs} element={<Layout><AboutUs /></Layout>} />
          <Route path={PATHS.gallery} element={<Layout><Gallery /></Layout>} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
