import cn from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
import { MENU, PATHS } from "../../const";
import { DiscordIcon, InstagramIcon, logo, TwitterIcon } from "../../images";
import { useEffect, useState } from "react";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const location = useLocation();

  const openMenu = () => {
    setMenuOpened(!menuOpened);
  };

  useEffect(() => {
    if (menuOpened) {
      document.body.style.cssText = `
            height: 100vh; 
            overflow: hidden;`;
    } else {
      document.body.removeAttribute("style");
    }
  }, [menuOpened]);

  const isLight =
    location.pathname === PATHS.home ||
    location.pathname === PATHS.ourBarber ||
    location.pathname === PATHS.roadmap;

  return (
    <header
      className={cn(styles["header"], { [styles["header_light"]]: isLight })}
    >
      <div className={`${styles.header__inwrap} container`}>
        <NavLink to="/" className={styles.navbar__brand__mobile}><img src={logo} alt="logo" /></NavLink>

        <div
          className={cn(styles["header__inner"], {
            [styles["mobile_menu_opened"]]: menuOpened,
          })}
        >
          <NavLink to="/" className={styles.navbar__brand}>
            <img src={logo} alt="logo" />
          </NavLink>

          <nav className={styles.nav}>
            {MENU.map((item) => (
              <NavLink
                key={item.id}
                to={item.url}
                className={`${styles.nav__link} nav__link`}
              >
                {item.name}
              </NavLink>
            ))}
          </nav>

          <div className={styles.social}>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className={styles.social__button}
            >
              <DiscordIcon className={styles.social__icon} />
            </a>
            <a
              rel="noreferrer"
              href="https://twitter.com/tbsnfts"
              target="_blank"
              className={styles.social__button}
            >
              <TwitterIcon className={styles.social__icon} />
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/tbsnfts/"
              target="_blank"
              className={styles.social__button}
            >
              <InstagramIcon className={styles.social__icon} />
            </a>
          </div>
        </div>

        <div
          className={cn(styles["nav__burger"], {
            [styles["opened"]]: menuOpened,
          })}
          onClick={openMenu}
        >
          <span className={styles.burger__line}></span>
        </div>
      </div>
    </header>
  );
};

export default Header;
